import { Text } from 'wix-ui-tpa/Text';
import { classes, st } from './BookingDetails.st.css';
import { AccessibilityHtmlTags } from '../../../../utils/AccessibilityHtmlTags.const';
import React, { useState } from 'react';
import { Badge, BADGE_PRIORITY } from 'wix-ui-tpa/Badge';
import { Button } from 'wix-ui-tpa/Button';
import { ReactComponent as CameraSVG } from 'wix-ui-tpa/dist/src/assets/icons/Camera.svg';
import { useEnvironment } from 'yoshi-flow-editor';
import { BookingDetailsDataHooks } from './BookingDetailsDataHooks';
import { BookingDetailsViewModel } from '../../ViewModel/bookingDetailsViewModel/bookingDetailsViewModel';
import { Divider } from 'wix-ui-tpa/Divider';
import { Dropdown } from 'wix-ui-tpa/Dropdown';
import { useCalendarActions } from '../../Actions/useCalendarActions';
import { TextButton } from 'wix-ui-tpa/TextButton';
import {
  mapSelectableBookingDetailToDropdownOptions,
  SelectableBookingDetails,
} from '../../../../utils/selectableBookingDetails/selectableBookingDetails';

export interface BookingDetailsProps {
  viewModel: BookingDetailsViewModel;
}

const SlotDetails: React.FC<BookingDetailsProps> = ({ viewModel }) => {
  const {
    serviceName,
    dateAndTime,
    paymentDescription,
    selectableBookingDetails,
    videoConferenceBadgeText,
  } = viewModel;

  return (
    <>
      <div
        data-hook={BookingDetailsDataHooks.SLOT_DETAILS}
        className={classes.serviceNameAndDate}
      >
        {videoConferenceBadgeText && (
          <Badge
            data-hook={BookingDetailsDataHooks.VIDEO_CONFERENCE_BADGE}
            priority={BADGE_PRIORITY.light}
            icon={<CameraSVG />}
            className={classes.videoConferenceBadge}
          >
            {videoConferenceBadgeText}
          </Badge>
        )}
        <Text
          data-hook={BookingDetailsDataHooks.SLOT_NAME}
          className={classes.commonTitleStyles}
          tagName={AccessibilityHtmlTags.Paragraph}
        >
          {serviceName}
        </Text>
        <Text
          data-hook={BookingDetailsDataHooks.SLOT_DATE_AND_TIME}
          className={classes.commonTextStyle}
          tagName={AccessibilityHtmlTags.Paragraph}
        >
          {dateAndTime}
        </Text>
      </div>
      {selectableBookingDetails.map((selectableBookingDetail) => {
        return selectableBookingDetail.options.length === 1 ? (
          <Text
            data-hook={
              BookingDetailsDataHooks.TOP_DETAILS +
              '-' +
              selectableBookingDetail.key
            }
            className={classes.commonTextStyle}
            tagName={AccessibilityHtmlTags.Paragraph}
          >
            {selectableBookingDetail.options[0].value}
          </Text>
        ) : null;
      })}
      <Text
        data-hook={BookingDetailsDataHooks.SLOT_PLAN_TYPE}
        className={classes.commonTextStyle}
        tagName={AccessibilityHtmlTags.Paragraph}
      >
        {paymentDescription}
      </Text>
    </>
  );
};
const SlotPreferences: React.FC<BookingDetailsProps> = ({ viewModel }) => {
  const {
    onSlotOptionSelected,
    onClearBookingDetailSelectedOptions,
  } = useCalendarActions();

  const {
    dateAndTime,
    selectableBookingDetails,
    bookingDetailsPreferencesHeaderText,
    bookingDetailsClearText,
  } = viewModel;

  const [clearCounter, setClearCounter] = useState(0);

  const clearDropdowns = () => {
    setClearCounter(clearCounter + 1);
    onClearBookingDetailSelectedOptions();
  };

  return (
    <div data-hook={BookingDetailsDataHooks.SLOT_PREFERENCES_WRAPPER}>
      <Divider
        className={classes.divider}
        data-hook={BookingDetailsDataHooks.DIVIDER}
      />
      <div className={classes.preferencesWrapper}>
        <Text
          data-hook={BookingDetailsDataHooks.SLOT_PREFERENCES_TITLE}
          className={classes.commonTitleStyles}
          tagName={AccessibilityHtmlTags.Paragraph}
        >
          {bookingDetailsPreferencesHeaderText}
        </Text>
        <TextButton
          data-hook={BookingDetailsDataHooks.SLOT_CLEAR_TITLE}
          className={classes.commonTitleStyles}
          onClick={() => clearDropdowns()}
        >
          {bookingDetailsClearText}
        </TextButton>
      </div>

      {selectableBookingDetails.map(
        (selectableBookingDetailsOption: SelectableBookingDetails) => {
          const options = mapSelectableBookingDetailToDropdownOptions(
            selectableBookingDetailsOption.options,
            selectableBookingDetailsOption.headerText,
          );
          if (selectableBookingDetailsOption.options.length > 1) {
            return (
              <Dropdown
                error={!!selectableBookingDetailsOption.error.message}
                errorMessage={selectableBookingDetailsOption.error.message}
                data-hook={
                  BookingDetailsDataHooks.DROPDOWN +
                  '-' +
                  selectableBookingDetailsOption.key
                }
                className={classes.commonDropDownStyle}
                placeholder={selectableBookingDetailsOption.headerText}
                options={options}
                key={
                  dateAndTime +
                  selectableBookingDetailsOption.key +
                  clearCounter
                }
                onChange={(slotDropdownOption) =>
                  onSlotOptionSelected({
                    key: selectableBookingDetailsOption.key,
                    value: slotDropdownOption.id!,
                  })
                }
              />
            );
          }
          return null;
        },
      )}
    </div>
  );
};
const BookingDetails: React.FC<BookingDetailsProps> = ({ viewModel }) => {
  const { isMobile, isRTL } = useEnvironment();
  const { onSubmit } = useCalendarActions();

  const shouldShowPreferences = () => {
    return selectableBookingDetails.some(
      (selectableBookingDetailsOption) =>
        selectableBookingDetailsOption.options.length > 1,
    );
  };
  const { selectableBookingDetails, bookingDetailsCtaText } = viewModel;

  return (
    <div
      data-hook={BookingDetailsDataHooks.BOOKING_DETAILS_SELECTION_WRAPPER}
      className={st(classes.root, { isMobile, isRTL })}
    >
      <SlotDetails viewModel={viewModel} />
      {shouldShowPreferences() ? (
        <SlotPreferences viewModel={viewModel} />
      ) : null}
      <Button
        className={st(classes.bookButton, { isMobile })}
        data-hook={BookingDetailsDataHooks.BOOK_BUTTON}
        upgrade
        onClick={() => onSubmit()}
      >
        {bookingDetailsCtaText}
      </Button>
    </div>
  );
};
export default BookingDetails;
