import React, { useState } from 'react';
import { BoxSelection, BoxSize } from 'wix-ui-tpa/BoxSelection';
import {
  TimeSelectionViewModel,
  TimeSlot,
} from '../../../ViewModel/timeSelectionViewModel/timeSelectionViewModel';
import { classes, st } from './TimeSelection.st.css';
import { useCalendarActions } from '../../../Actions/useCalendarActions';
import { Text } from 'wix-ui-tpa/Text';
import ChevronDown from 'wix-ui-icons-common/ChevronDown';
import { TEXT_BUTTON_PRIORITY, TextButton } from 'wix-ui-tpa/TextButton';

export type TimeSelectionProps = {
  viewModel: TimeSelectionViewModel;
};

const TimeSelection: React.FC<TimeSelectionProps> = ({ viewModel }) => {
  const {
    timeSlots,
    shouldLimitNumberOfTimeSlotsDisplayed,
    maxNumberOfTimeSlotsToDisplay,
    showAllButtonText,
  } = viewModel;
  const { onTimeSelected } = useCalendarActions();
  const defaultNumberOfSlotsToDisplay = shouldLimitNumberOfTimeSlotsDisplayed
    ? maxNumberOfTimeSlotsToDisplay
    : timeSlots.length;
  const [numberOfTimeSlotsToDisplay, setNumberOfTimeSlotsToDisplay] = useState(
    defaultNumberOfSlotsToDisplay,
  );
  const shouldDisplayShowAllButton =
    timeSlots.length > numberOfTimeSlotsToDisplay;

  return (
    <div className={classes.timeSelection}>
      <BoxSelection
        name="time-selection"
        data-hook="time-selection"
        className={classes.boxSelection}
        size={BoxSize.small}
        onChange={({ id }) => onTimeSelected(id)}
      >
        {timeSlots
          .slice(0, numberOfTimeSlotsToDisplay)
          .map((timeSlot: TimeSlot, index: number) => {
            const {
              selected,
              allSlotsAreFull,
              tooLateToBookAllSlots,
              tooEarlyToBookAllSlots,
            } = timeSlot.status;
            const isDisabled =
              allSlotsAreFull ||
              tooLateToBookAllSlots ||
              tooEarlyToBookAllSlots;
            return (
              <BoxSelection.Option
                key={index}
                id={timeSlot.rfcStartTime}
                className={classes.boxSelectionOption}
                aria-label={timeSlot.ariaLabel}
                checked={selected}
                disabled={tooEarlyToBookAllSlots}
                unavailable={allSlotsAreFull || tooLateToBookAllSlots}
              >
                <Text
                  data-hook={`time-slot-text-${index}`}
                  className={st(classes.boxSelectionText, {
                    isDisabled,
                  })}
                >
                  {timeSlot.formattedStartTime}
                </Text>
              </BoxSelection.Option>
            );
          })}
      </BoxSelection>
      {shouldDisplayShowAllButton ? (
        <TextButton
          data-hook="show-all-button"
          priority={TEXT_BUTTON_PRIORITY.secondary}
          suffixIcon={<ChevronDown />}
          onClick={() => setNumberOfTimeSlotsToDisplay(timeSlots.length)}
          className={classes.showAllButton}
        >
          {showAllButtonText}
        </TextButton>
      ) : null}
    </div>
  );
};

export default TimeSelection;
