import React from 'react';
import { classes, st } from './TimePickerNotification.st.css';
import {
  SectionNotification,
  TEXT_BUTTON_PRIORITY,
} from 'wix-ui-tpa/SectionNotification';
import { TextButton } from 'wix-ui-tpa/TextButton';
import { TimePickerNotificationViewModel } from '../../../ViewModel/timePickerNotificationViewModel/timePickerNotificationViewModel';
import { useCalendarActions } from '../../../Actions/useCalendarActions';

export type TimePickerNotificationProps = {
  viewModel?: TimePickerNotificationViewModel;
};

const TimePickerNotification: React.FC<TimePickerNotificationProps> = ({
  viewModel,
}) => {
  const { onTimePickerNotificationCtaClick } = useCalendarActions();

  return viewModel ? (
    <SectionNotification
      type="default"
      className={classes.notification}
      data-hook="time-picker-notification"
    >
      <SectionNotification.Text className={classes.notificationText}>
        {viewModel.messageText}
        {viewModel.ctaText ? (
          <div>
            <TextButton
              className={classes.notificationCTA}
              priority={TEXT_BUTTON_PRIORITY.link}
              data-hook="time-picker-notification-cta"
              onClick={() =>
                onTimePickerNotificationCtaClick(viewModel.notificationType!)
              }
            >
              {viewModel.ctaText}
            </TextButton>
          </div>
        ) : null}
      </SectionNotification.Text>
    </SectionNotification>
  ) : null;
};

export default TimePickerNotification;
